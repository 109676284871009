<template>
  <v-card class="pa-0">
    <v-img
      class="w-full"
      src="@/assets/images/toppage/town_infirmary.jpg"
      alt=""
      height="50vw"
    />
    <v-card-title>
      {{ $t('HealthInformation') }}
    </v-card-title>
    <v-list
      v-if="getListHealthInfo.length > 0"
      class="pb-4"
    >
      <template
        v-if="getIsLoadingListHealthInfo"
      >
        <div class="d-flex items-center pb-3">
          <v-progress-circular
            indeterminate
            color="primary"
            class="mx-auto"
          ></v-progress-circular>
        </div>
      </template>
      <template
        v-for="(item, index) in getListHealthInfo"
        v-else
      >
        <v-list-item
          :key="item.id_user"
          :to="{name: 'town-infirmary-user-view', params: {id: item.id_user}}"
        >
          <v-list-item-avatar>
            <v-img :src="item.face_image_url || require('@/assets/images/avatars/1.png')"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ item.full_name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="text--primary">
                {{ $t(typeMember(item.type_member)) }}
              </span><br />
              <span
                class="text-sm"
                style="white-space: normal "
              >{{ $t('LastPhysicalExamination') }}: {{ formatDate(item.last_examination_at * 1000,'DD/MM/YYYY h:mm:ss') }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider
          v-if="index < items.length - 2"
          :key="`divider-${item.id_user}`"
          :inset="item.inset"
        ></v-divider>
      </template>
    </v-list>
    <v-card-text
      v-else
    >
      {{ $t('NoData') }}
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiClipboardCheckOutline } from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import { typeMember } from '@/utils/familyHelper'

// import { getRole } from '@/utils/accountHelper'

export default {
  data() {
    return {
      icons: { mdiClipboardCheckOutline },
      userTypeMapping: ['You', 'Child'],
      items: [
        {
          id: 1,
          avatar: require('@/assets/images/avatars/1.png'),
          title: 'Ali Connors',
          subtitle: {
            type: 0,
            desc: '2021-10-29 10:30',
          },
        },
        {
          id: 2,
          avatar: require('@/assets/images/avatars/3.png'),
          title: 'Summer BBQ ',
          subtitle: {
            type: 1,
            desc: '2021-10-29 10:30',
          },
        },
        {
          id: 3,
          avatar: require('@/assets/images/avatars/2.png'),
          title: 'Oui oui',
          subtitle: {
            type: 1,
            desc: '2021-10-29 10:30',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters('town', ['getListHealthInfo', 'getIsLoadingListHealthInfo']),
    ...mapGetters('auth', ['currentUser']),
    params() {
      const data = {
        view_family: 1,

        // id_family: this.currentUser.id_family,
      }

      if (this.currentUser.id_family) {
        data.id_family = this.currentUser.id_family
      }

      if (this.currentUser.role > 1) {
        data.as_user = 1
      }

      return data
    },
  },

  // watch: {
  //   getListHealthInfo(value) {
  //     console.log('list health', value)
  //   },
  // },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions('town', ['fetchListExamination']),
    getData() {
      this.fetchListExamination(this.params)
    },
    typeMember,
  },
}
</script>
